


export const testData = {
    address: "浙江 杭州",
    birthday: null,
    constellation: null,
    createTime: "2020-08-18T10:53:31.000+0800",
    feeling: null,
    headImg: "http://sy-community.oss-cn-hangzhou.aliyuncs.com/shop/2021/04/07/d98528060423456daf0286832688c3e0-1617762923.png",
    hometown: null,
    id: 1,
    inviteCode: "edon8728",
    isFriend: 0,
    momentImg: "http://sy-community.oss-cn-hangzhou.aliyuncs.com/friend/2020/09/05/4ce065950f5d4a8ab1b849164d1a97b6-174774be4af.jpeg,http://sy-community.oss-cn-hangzhou.aliyuncs.com/friend/2020/09/05/b3bd25d001d6448eaae98fb919730b40-17455b7811d.jpeg,http://sy-community.oss-cn-hangzhou.aliyuncs.com/friend/2020/09/05/0d6ed453a7a6437db1a8985b5ef0dc11-174a0bb3871.jpeg,http://sy-community.oss-cn-hangzhou.aliyuncs.com/friend/2020/09/05/5d84ceb4575d4f9d847259d8029a2244-1742813e71c.jpeg,http://sy-community.oss-cn-hangzhou.aliyuncs.com/friend/2020/09/05/4f1435a0ebd04526876a78cabf83df0b-174c82bd433.jpeg,http://sy-community.oss-cn-hangzhou.aliyuncs.com/friend/2020/09/05/e3e817eaf69240279a53ecbdcfb2b1f7-1740b4c9e8e.jpeg",
    mood: "今天天气很不错",
    name: "菜鸟驿站客服",
    nickName: "菜鸟驿站客服",
    openId: "oKXnO0U1KVzoXXoftpWjpKTehwZA",
    password: "$2a$10$G3E5Wskxu1zYs4Gb1ZTojeA2ZMusduuRRv.jDjraBy3KxYwqqwCLO",
    payPassword: null,
    personSign: "",
    phone: "17767055447",
    pushId: "ED345A1B-0B13-4183-9ED0-F425AEFA9D4C",
    recommendCode: null,
    remark: null,
    school: null,
    schoolName: null,
    sex: "1",
    status: false,
    unionid: null,
    updateTime: "2021-08-26T15:21:06.000+0800",
    userDevice: "ios",
    xiaoOpenId: "oRGxs5aOLxIky8maNHMTagnD7ygY",
}
