import {findFriendSuc} from "@/Api/Friend";
import Storage from "@/util/Storage"
import { AwaitApplyFriend } from '@/Api/Message';

// 判断是否是朋友
export function getIsFriends( id:string|number ) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( findFriendSuc({ userId,token },{ friendId:id }).then(res=>{
        return res.data[0] && res.data[0] || {}
    }) )
}


// 处理申请 朋友
export function disposeApplyFriend( id:string|number,status:string|number ) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( AwaitApplyFriend({ userId,token },{ id,status }).then(res=>{
        return res.data
    }) )
}
